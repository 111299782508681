const messages = {
    en: {
    },
    es: {
        general:{
        },
        home:{
            title: 'CERBERUS'
        },
        dev_services:{
            title: 'Servicios Expuestos',
            cas: 'Centralized Authentication Service',
            login: 'Inicio de sesión',
            logout: 'Cerrar sesión',
            verify: 'Verificar token de autentificación',
            create: 'Creación de usuario',
            reset_password: 'Restaurar contraseña'
        },

        error: {
            '401_title': 'Error 401',
            '401_message': 'Usuario o contraseña no son correctas.',
            '500_title': 'Error 500',
            '500_message': 'What happend.'
        },

        password_change: {
            title: 'Cambio de contraseña',
            subtitle: {
                send: 'Dígite su correo para el envío del correo de restauración de la contraseña',
                change: 'Dígite una nueva contraseña'
            },
            placeholder: 'Ingrese su correo electrónico',
            submit: 'Enviar',
            alert: {
                validation: {
                    message: '¡Se venció el enlace para el cambio de contraseña!',
                    message_strong: 'Solicite uno nuevo.'
                },

                message_sent: '¡El mensaje fue enviado exitosamente! Por favor revise su correo para continuar.',
                comparation: 'Las contraseñas no coinciden, por favor verificar'
            },
            new_password: {
                placeholder: 'Ingrese una nueva contraseña',
                confirmation_placeholder: 'Ingrese nuevamente la contraseña',
                show: "Mostrar",
                hide: "Ocultar"
            },
            email: {
                validator_message: 'Por favor ingrese una dirección de correo válida'
            }
        }
    }
}

export default messages