function isAuthenticated(state) {
	state.authenticated = localStorage.getItem('authenticated') ? true : false;
	return state.authenticated;
}

function getCurrentUser(state) {
	if (!state.currentUser) {
		state.currentUser =
			localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : null;
	}
	return state.currentUser;
}

function getError(state) {
	return state.error
}

function isLoading(state) {
	return state.loading;
}

function getCurrentContract(state) {
	if (!state.currentContractProcess && !state.currentContractProcess.contract) {
		state.currentContractProcess = localStorage.getItem('currentContractProcess') ? JSON.parse(localStorage.getItem('currentContractProcess')) : null;
	}
	return state.currentContractProcess == null || state.currentContractProcess.contract == undefined ? false : state.currentContractProcess.contract
}

function getProcesses(state) {
	if (!state.currentContractProcess && !state.currentContractProcess.processes) {
		state.currentContractProcess = localStorage.getItem('currentContractProcess') ? JSON.parse(localStorage.getItem('currentContractProcess')) : null;
	}
	return state.currentContractProcess == null || state.currentContractProcess.processes == undefined ? false : state.currentContractProcess.processes
}

function getCurrentProcess(state) {
	if (!state.currentProcess) {
		state.currentProcess = localStorage.getItem('currentProcess') ? JSON.parse(localStorage.getItem('currentProcess')) : null;
	}
	return state.currentProcess
}

function getMenu(state) {
	if (!state.menu) {
		state.menu = localStorage.getItem('menu') ? JSON.parse(localStorage.getItem('menu')) : null;
	}
	return state.menu
}

export default {
	isLoading,
	getError,
	isAuthenticated,
	getCurrentUser,
	getCurrentContract,
	getProcesses,
	getCurrentProcess,
	getMenu
}