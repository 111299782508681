function setAuthenticated(state, payload) {
	state.authenticated = payload;
	if (payload) {
		localStorage.setItem("authenticated", payload);
	} else {
		localStorage.removeItem("authenticated");
	}
}

function setCurrentUser(state, payload) {
	state.currentUser = payload;
	if (payload) {
		localStorage.setItem("currentUser", JSON.stringify(payload));
	} else {
		localStorage.removeItem("currentUser");
	}
}
function setError(state, payload) {
	state.error = payload;
}

function increaseLoading(state) {
	state.loading += 1;
}
function decreaseLoading(state) {
	if (state.loading < 0) {
		state.loading = 0;
	} else {
		state.loading -= 1;
	}
}
function setContract(state, payload) {
	state.currentContractProcess = payload;
	if (payload) {
		localStorage.setItem('currentContractProcess', JSON.stringify(payload));
	} else {
		localStorage.removeItem('currentContractProcess');
	}
}

function selectCurrentProcess(state, payload) {
	state.currentProcess = payload;
	if (payload) {
		localStorage.setItem('currentProcess', JSON.stringify(payload));
	} else {
		localStorage.removeItem('currentProcess');
	}
}

function setMenu(state, payload) {
	state.menu = payload;
	if (payload) {
		localStorage.setItem('menu', JSON.stringify(payload));
	} else {
		localStorage.removeItem('menu');
	}
}

export default {
	increaseLoading,
	decreaseLoading,
	setError,
	setCurrentUser,
	setAuthenticated,
	setContract,
	selectCurrentProcess,
	setMenu
};
