const state = {
  authenticated: false,
  currentUser: false,
  currentContractProcess: false,
  currentProcess: false,
  menu: false,
  error: {
      status: false,
      typer: 0
  },
  loading: 0
}

export default state;
