import Vue from 'vue'
import VueRouter from 'vue-router'
import messages from '@/i18n';
const HomeView = () => import(/* webpackChunkName: "about" */ '../components/views/HomeView.vue');
const NotFoundView = () => import('../components/views/NotFoundView.vue');
const LayoutComponent = () => import('../components/shared/LayoutComponent.vue');
const BodyComponent = () => import('../components/shared/BodyComponent.vue');
const DevServiceView = () => import('../components/views/DevServiceView')
const PasswordChangeView = () => import('../components/views/PasswordChangeView')

Vue.use(VueRouter);
var userLang = navigator.language.substring(0,2) || navigator.userLanguage.substring(0,2);
const routes = [
  {
    path: '/',
    component: LayoutComponent,
    children:[
      {
        path: '',
        component: BodyComponent,
        children:[
          {
            path: '',
            name: 'home',
            component: HomeView,
            meta:{
              title: messages[userLang].home && messages[userLang].home.title  ? messages[userLang].home.title : 'CERBERUS'
            }
          },
          {
            path: '/dev-sev',
            name: 'dev-sev',
            component: DevServiceView,
            meta:{
              title: messages[userLang].test_components && messages[userLang].test_components.title ? messages[userLang].test_components.title : 'CERBERUS'
            }
          },

          // TODO: Agregar las demas vistas en este lugar
        ]
      }
    ]
  },
  {
    // path: "*",
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFoundView,
  } 
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.afterEach((to, from) => {
  Vue.nextTick( () => {
    document.title = to.meta.title ? to.meta.title : 'PREST';
  });
});

export default router;
