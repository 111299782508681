import VAPI from "../../../http_common";
import { SERVICE_NAMES, HTTP_STATUS } from "../../../app_constants";
import router from "../../../router/index";

function logIn({ commit, dispatch }, payload) {
	let data = {
		username: payload.username,
		password: payload.password,
		userAgent: navigator.userAgent,
	};
	VAPI.post(`/${SERVICE_NAMES.LOGIN}`, data)
		.then((response) => {
			if (response.status == HTTP_STATUS.OK) {
				commit("setAuthenticated", true);
				commit("setCurrentUser", response.data);
				dispatch("contract");
				dispatch("getMenu");
				router.push({
					name: "home",
				});
			} else if (response.status == HTTP_STATUS.NO_CONTENT) {
				commit("setError", { status: true, type: response.status });
			}
		})
		.catch((error) => {
			commit("setError", { status: true, type: error.response.status });
			console.error(error);
		});
}

function logOut({ commit, dispatch }, payload) {
	let currentUser = JSON.parse(localStorage.getItem("currentUser"));
	let data = { userAgent: navigator.userAgent };
	if (currentUser == null) {
		commit("setAuthenticated", false);
		commit("setCurrentUser", null);
		commit("setContract", false);
		commit("selectCurrentProcess", false);
		commit("setMenu", false);
	} else {
		VAPI.post(`/${SERVICE_NAMES.LOGOUT}`, data)
			.then((response) => {
				commit("setAuthenticated", false);
				commit("setCurrentUser", null);
				commit("setContract", false);
				commit("selectCurrentProcess", false);
				commit("setMenu", false);
				router.push({
					name: "login",
				});
			})
			.catch((error) => {
				console.error("An error occurred logout: " + error.toString());
				commit("setAuthenticated", false);
				commit("setCurrentUser", null);
				commit("setContract", false);
				commit("selectCurrentProcess", false);
				commit("setMenu", false);
				if (error.response != undefined)
					commit("setError", { status: true, type: error.response.status });
				router.push({
					name: "login",
				});
			});
	}
}

function clearLoading({ commit }) {
	commit("decreaseLoading");
}

function setLoading({ commit }) {
	commit("increaseLoading");
}

function contract({ commit, dispatch }) {
	VAPI.get(`/${SERVICE_NAMES.GENERAL_DB}/contract`)
		.then((response) => {
			if (response.status == HTTP_STATUS.OK) {
				commit("setContract", response.data);
				dispatch(
					"setCurrentProcess",
					response.data.processes.length > 0 ? response.data.processes[0] : null
				);
			} else if (response.status == HTTP_STATUS.NO_CONTENT) {
				commit("setError", { status: true, type: response.status });
			}
		})
		.catch((error) => {
			commit("setError", { status: true, type: "PRESTERROR01" });
			console.error(error);
		});
}

function setCurrentProcess({ commit }, payload) {
	commit("selectCurrentProcess", payload);
}

function getMenu({ commit, dispatch}, payload) {
	VAPI.get(`/${SERVICE_NAMES.USER}/menu`)
		.then((response) => {
			if (response.status == HTTP_STATUS.OK){
				commit('setMenu', response.data)
			} else if (response.status == HTTP_STATUS.NO_CONTENT) {
				commit("setError", { status: true, type: response.status });
			}
		}).catch((error)=>{
			commit("setError", { status: true, type: "PRESTERROR02" });
			console.error(error);
		});
}

export default {
	logIn,
	logOut,
	clearLoading,
	setLoading,
	contract,
	setCurrentProcess,
	getMenu
};
