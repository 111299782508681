import axios from 'axios'
import {URI, HTTP_STATUS} from './app_constants'
import store from './store'
import router from "./router/index";
const VAPI = axios.create({
    baseURL: URI,
    timeout: 60000,
  })

  VAPI.defaults.withCredentials = true;

  VAPI.interceptors.request.use(function(config) {
    store.dispatch('setLoading');
    return config
  })
  
  VAPI.defaults.headers = {
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0',
  };
  
  VAPI.interceptors.response.use(function(response) {
    store.dispatch('clearLoading');
    if(response.data.code == HTTP_STATUS.UNAUTHORIZED && router.currentRoute.name == "Home") store.dispatch('logOut',{redirect:false})
    else if(response.data.code == HTTP_STATUS.UNAUTHORIZED) store.dispatch('logOut',{redirect:true})
    return Promise.resolve(response) 
  }, function(error) {
    store.dispatch('clearLoading');
    if (error.response) {
      if(error.response.status == HTTP_STATUS.UNAUTHORIZED && router.currentRoute.name == "Home") store.dispatch('logOut',{redirect:false})
      else if(error.response.status == HTTP_STATUS.UNAUTHORIZED) store.dispatch('logOut',{redirect:true})
      store.commit("setError", {status:true, type: error.response.data.code})
      return Promise.reject(error);
    }
  })
  
export default VAPI